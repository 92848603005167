import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import App from "./containers/app";
import GlobalStyle from "@src/styled/globalStyles";
import { history } from "./modules";

// import LinearIcons from "@src/styled/linear-icons";
import "./styles/ui-home-2021.scss";
import "react-tooltip/dist/react-tooltip.css";

const target = document.querySelector("#root");
const root = createRoot(target); // createRoot(container!) if you use TypeScript

const appElement = (
  <>
    <GlobalStyle />
    {/* <LinearIcons /> */}
    <Router history={history}>
      <div>
        <App />
      </div>
    </Router>
  </>
);

if (target.hasChildNodes()) {
  console.log("hydratate");
  hydrateRoot(target, appElement);
} else {
  console.log("render");
  root.render(appElement);
}

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
