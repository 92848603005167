import React from "react";
import DoctoRoutes from "./domains/docto.com/index";
// import HemisphereRoutes from "./domains/hemisphere.com/index";

const App = () => {
  const domain = window.location.hostname;

  switch (domain) {
    // case "hemisphere.qat.docto.com.au":
    // case "hemisphere.staging.docto.com.au":
    // case "hemisphere.docto.com.au":
    // case "hemisphere.localhost":
    // case "hemispheretelehealth.com":
    //   return <HemisphereRoutes />;
    default:
      return <DoctoRoutes />;
  }
};

export default App;
